@tailwind base;
@tailwind components;
@tailwind utilities;


:root {
    --color-primary: #ec4755;
    --color-secondary: #a12c34;
    --color-tertiaty: #99a0a3;
}

a {
    @apply text-red-700;
}

a:hover {
    @apply border-b-2 border-red-700;
}


.content p {
    @apply my-6;
}

.content ul {
    @apply my-6;
}

img, video {
    max-width: 100%;
    height: auto;
}

img, svg, canvas, audio, iframe, embed, object {
    display: block;
    vertical-align: middle;
}

.outer_container {
    position: absolute;
    width: 100%;
    max-height: 100%;
    height: 100vh;
}

.input_video {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.input_video.selfie {
    transform: scale(-1, 1);
}

.canvas-container {
    display:block;
    height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items:center;
}

.output_canvas {
    width: 100vw;
    display: block;
    position: relative;
    height: 100vh;
    z-index: 0;
}

.video_output_canvas {
    width: 20vw;
    max-height: 30vh;
    position: fixed;
    top: 2rem;
    right: 3rem;
    background: #3d3d3d;
    opacity: 0.8;
    z-index: 1;
}
